import React from 'react'
import styled from 'styled-components'

import { largerThan } from 'styles/media'
import { borderRadius } from 'styles/variables'
import { boxShadow } from 'styles/helpers'
import { fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'

const Container = styled.aside`
  border-bottom: 1px solid ${({ theme }) => theme.border};
  padding-bottom: ${spacing.large};
  margin-bottom: ${spacing.small};

  ${largerThan.small`
    padding-bottom: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    position: sticky;
    padding: ${spacing.large};
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: ${borderRadius};
    top: ${spacing.large};
    margin-left: ${spacing.large};
    ${boxShadow.small};
  `};

  ${largerThan.large`
    margin-left: ${spacing.xLarge};
    padding: ${spacing.xLarge};
  `};

  ${largerThan.xLarge`
    top: ${spacing.xLarge};
    margin-left: ${spacing.xxLarge};
  `};
`

const Inner = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};

  ${largerThan.small`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing.large};
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${spacing.medium};
  `};

  ${largerThan.large`
    grid-gap: ${spacing.large};
  `};
`

const ItemContainer = styled.div`
  display: block;
`

const ItemLabel = styled(Heading)`
  display: block;
  margin-bottom: ${spacing.tiny};

  ${largerThan.medium`
    margin-bottom: ${spacing.scale(spacing.tiny, 1.5)};
  `};
`

const ItemMeta = styled.span`
  display: inline-block;
  font-size: ${fontSizes.small};
  color: ${({ theme }) => theme.muted};
  font-style: italic;
  margin-left: ${spacing.tiny};
`

const JobListingDetails = ({ salary, name }) => (
  <Container>
    <Inner>
      <Item label="Type" value="Full-time" />
      <Item label="Location" value="Montreal, Canada (Remote)" />
      <Item label="Salary" value={salary} meta="+ profit sharing" />
    </Inner>
  </Container>
)

const Item = ({ label, value, meta }) => (
  <ItemContainer>
    <ItemLabel size={6}>{label}</ItemLabel>
    <span>{value}</span>
    {meta && <ItemMeta>{meta}</ItemMeta>}
  </ItemContainer>
)

export default JobListingDetails
