import React from 'react'

import JobListing from 'components/careers/JobListing'

const ProductDesigner = () => (
  <JobListing
    name="Product Designer"
    salary="$65k – $85k"
    overview={
      <>
        <p>
          We're looking for a Product Designer (2-5 years experience) with solid
          front-end development capabilities to join our growing team. You'll
          work alongside developers on challenging client projects, and report
          directly to our Co-Founder & Design Director.
        </p>
      </>
    }
    tasks={[
      'Participate in discovery, project planning, design research and brainstorming',
      'Create designs for web and mobile platforms (including user flows, wireframes, prototypes and high-fidelity mockups)',
      'Present your work internally and to our clients',
      'Translate your designs to code for developers to implement',
      'Collaborate with developers to insure proper implementation',
      'Contribute to our design process',
    ]}
    qualifications={[
      '2-5 years experience designing applications for web and mobile',
      'Up-to-date knowledge of design tools (Figma, Sketch, InVision)',
      'Solid grasp of front-end best practices and limitations (HTML/CSS)',
      'Strong portfolio of related design projects',
      'Excellent communication skills',
      'Extreme attention to detail',
      'Willingness to learn and grow',
      'Bonus: copywriting experience',
      'Bonus: familiarity with React components/styled-components',
    ]}
  />
)

export default ProductDesigner
