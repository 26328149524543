export function widowFix(str) {
  if (typeof str === 'string') {
    const cleanStr = str.trim()
    const lastSpace = cleanStr.lastIndexOf(' ')
    const lastNonBreakingSpace = cleanStr.lastIndexOf('\xa0')

    if (lastSpace > lastNonBreakingSpace) {
      return `${cleanStr.substr(0, lastSpace)}\xa0${cleanStr.substr(
        lastSpace + 1
      )}`
    }
    return cleanStr
  }
  return undefined
}
