import React from 'react'
import styled from 'styled-components'

import { widowFix } from 'utils/string'

import { largerThan } from 'styles/media'
import { fontFamilies, fontWeights, lineHeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Breadcrumbs from 'components/Breadcrumbs'
import Heading from 'components/Heading'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import PageFigure from 'components/work/PageFigure'

const Main = styled.main`
  position: relative;
  z-index: 2;
`

const Title = styled(Heading)`
  margin-bottom: 0;

  span {
    position: static;

    ${largerThan.medium`
      display: block;
    `};
  }
`

const Description = styled(Heading)`
  color: ${({ theme }) => theme.body};
  font-family: ${fontFamilies.body};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.base};
  margin: 0;
  max-width: 660px;
  margin-top: -${spacing.scale(spacing.small, 1.5)};

  ${largerThan.medium`
    margin-top: 0;
  `};

  ${largerThan.large`
    padding-right: ${spacing.large};
  `};

  ${largerThan.xLarge`
    padding-right: ${spacing.xLarge};
  `};
`

const PageHeader = ({ breadcrumbs, heading, description, figure }) => (
  <>
    <Section flushBottom={!!figure}>
      <Main>
        <Wrapper>
          {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
          <Grid>
            <GridRow>
              <GridColumn xs={12} md={description ? 6 : 12}>
                <Title tag="h1" size={1}>
                  {heading}
                </Title>
              </GridColumn>

              {description && (
                <GridColumn xs={12} md={6}>
                  <Description size={4} tag="h2">
                    {widowFix(description)}
                  </Description>
                </GridColumn>
              )}
            </GridRow>
          </Grid>
        </Wrapper>
      </Main>
    </Section>
    {!!figure && <PageFigure figure={figure} />}
  </>
)

export default PageHeader
