import React from 'react'
import styled from 'styled-components'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import Section from 'components/Section'
import RichText from 'components/RichText'
import Button from 'components/button/Button'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import JobListingDetails from './JobListingDetails'

const ListingSection = styled.section`
  position: static;

  & + & {
    margin-top: ${spacing.large};

    ${largerThan.medium`
      margin-top: ${spacing.xLarge};
    `};

    ${largerThan.xLarge`
      margin-top: ${spacing.xxLarge};
    `};
  }
`

const Main = styled.main`
  position: static;

  ${largerThan.large`
    padding-right: ${spacing.large};
  `};

  ${largerThan.xLarge`
    padding-right: ${spacing.xxLarge};
  `};
`

const Disclaimer = styled.small`
  display: block;
  font-style: italic;
`

const JobListing = ({ name, overview, salary, tasks, qualifications }) => (
  <>
    <MetaTags
      title={`${name} | Custom software development in Montreal | Volume7`}
    />
    <PageHeader
      breadcrumbs={[
        {
          label: 'Careers',
          link: '/careers/',
        },
        {
          label: name,
        },
      ]}
      heading={name}
    />

    <Section flushTop>
      <Wrapper>
        <Grid>
          <GridRow reverse={['md', 'lg', 'xl']}>
            <GridColumn xs={12} md={4}>
              <JobListingDetails salary={salary} name={name} />
            </GridColumn>
            <GridColumn xs={12} md={8}>
              <Main>
                <ListingSection>
                  <Heading size={4} tag="h2">
                    The role
                  </Heading>
                  <RichText>
                    {overview}
                    <Disclaimer>
                      This is a remote work opportunity, but we are currently
                      looking for candidates based in Montreal, Canada.
                    </Disclaimer>
                  </RichText>
                </ListingSection>
                <ListingSection>
                  <Heading size={4} tag="h2">
                    What you'll do
                  </Heading>
                  <RichText>
                    <ul>
                      {tasks.map((task, i) => (
                        <li key={i}>{task}</li>
                      ))}
                    </ul>
                  </RichText>
                </ListingSection>
                <ListingSection>
                  <Heading size={4} tag="h2">
                    Qualifications
                  </Heading>
                  <RichText>
                    <ul>
                      {qualifications.map((qualification, i) => (
                        <li key={i}>{qualification}</li>
                      ))}
                    </ul>
                  </RichText>
                </ListingSection>
                <ListingSection>
                  <Heading size={4} tag="h2">
                    Benefits and perks
                  </Heading>
                  <RichText>
                    <ul>
                      <li>Generous health and dental coverage</li>
                      <li>Quarterly profit sharing</li>
                      <li>Flexible schedule</li>
                      <li>Fun/challenging projects</li>
                      <li>Work from home</li>
                      <li>Generous paid time off</li>
                      <li>Annual team retreats</li>
                      <li>Relaxed work environment</li>
                    </ul>
                  </RichText>
                </ListingSection>
                <ListingSection>
                  <Heading size={4} tag="h2">
                    About Volume7
                  </Heading>
                  <RichText>
                    <p>
                      We're a software development agency (est. 2016) building
                      digital products for enterprise and high-growth companies.
                      Working remotely from Montreal, we're a tight-knit team of
                      experts who pride themselves in doing great work within a
                      relaxed environment.
                    </p>
                  </RichText>
                </ListingSection>
                <ListingSection>
                  <Button
                    href={`mailto:careers@volume7.io?subject=${name} application`}
                  >
                    Apply now
                  </Button>
                </ListingSection>
              </Main>
            </GridColumn>
          </GridRow>
        </Grid>
      </Wrapper>
    </Section>
  </>
)

export default JobListing
