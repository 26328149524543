import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { largerThan } from 'styles/media'
import { cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'

import Section from 'components/Section'

const Container = styled.div`
  position: relative;
  margin-top: -${spacing.xxLarge};

  ${largerThan.small`
    margin-top: -${spacing.xxxLarge};
  `};

  ${largerThan.medium`
    margin-top: -4rem;
  `};

  ${largerThan.large`
    margin-top: -5rem;
  `};

  ${largerThan.xLarge`
    margin-top: -6rem;
  `};
`

const Inner = styled.div`
  position: relative;
  display: block;
  background-color: #ddd;

  > * {
    ${cover('absolute')};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 60%;
  }
`

const Gradient = styled.div`
  background-image: linear-gradient(
    180deg,
    ${({ theme }) => theme.background} 0%,
    ${({ theme }) => rgba(theme.background, 0)} 100%
  );
  z-index: 1;
  ${cover('absolute')};
`

const PageFigure = ({ figure }) => (
  <Section flushTop>
    <Container>
      <Gradient />
      <Inner>{figure}</Inner>
    </Container>
  </Section>
)

export default PageFigure
