import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

import Heading from 'components/Heading'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.medium};

  ${largerThan.small`
    margin-bottom: ${spacing.large};
  `};

  ${largerThan.large`
    margin-bottom: ${spacing.xLarge};
  `};
`

const Item = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: ${({ theme }) => theme.muted};

  ${(props) =>
    props.actionable &&
    css`
      color: ${({ theme }) => theme.heading};

      &:after {
        content: '/';
        line-height: 1; /* Don't take up more vertical space than needed */
        margin: 0 ${spacing.tiny};

        ${largerThan.large`
          margin: 0 ${spacing.small};
        `};
      }
    `}

  ${(props) =>
    !props.actionable &&
    css`
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

const Label = styled(Heading)`
  margin: 0;
  color: currentColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Breadcrumbs = ({ items }) => (
  <Container>
    {items.map((item, i) => {
      const el = item.link ? Link : 'span'

      return (
        <Item
          key={i}
          as={el}
          to={item.link}
          actionable={item.link ? true : false}
        >
          <Label size={6} tag="span">
            {item.label}
          </Label>
        </Item>
      )
    })}
  </Container>
)

export default Breadcrumbs
