import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import * as spacing from 'styles/spacing'
import { primaryColor } from 'styles/colors'
import { square } from 'styles/helpers'
import { largerThan } from 'styles/media'
import { lineHeights, Lead } from 'styles/typography'

const Container = styled.div`
  position: static;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.heading};
  }

  h1,
  h2 {
    line-height: ${lineHeights.tight};
  }

  h3,
  h4,
  h5,
  h6 {
    line-height: ${lineHeights.heading};
  }

  p {
    color: ${({ theme }) => theme.body};
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${largerThan.medium`
      margin-bottom: 1.5em;
    `};
  }

  ${Lead} {
    color: ${({ theme }) => theme.heading};
  }

  ul,
  ol {
    color: ${({ theme }) => theme.body};
    padding-left: ${spacing.scale(spacing.medium, 1.25)};
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${largerThan.medium`
      margin-bottom: 1.5em;
      padding-left: ${spacing.large};
    `};

    ul,
    ol {
      margin-top: 0.75em;
      font-size: 0.9em;

      li:before {
        background-color: ${({ theme }) => rgba(theme.muted, 0.66)};
      }
    }

    li + li {
      margin-top: ${spacing.small};

      ${largerThan.medium`
        margin-top: ${spacing.scale(spacing.small, 1.5)};
      `};
    }
  }

  ul {
    list-style: none;
  }

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0.6em; /* Optically align dot with text */
      left: -${spacing.scale(spacing.medium, 1.25)};
      background-color: ${primaryColor};
      ${square('5px')};

      ${largerThan.medium`
        left: -${spacing.large};
        ${square('6px')};
      `};
    }
  }

  p a,
  ul a,
  ol a {
    color: ${({ theme }) => theme.heading};
    border-bottom: 1px solid;
  }
`

const RichText = ({ children }) => <Container>{children}</Container>

export default RichText
